
import * as sanityRuntime$W6w2KXD5gp from '/opt/build/repo/nuxt/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.1/node_modules/@nuxt/image/dist/runtime/providers/sanity'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "sanity",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['sanity']: { provider: sanityRuntime$W6w2KXD5gp, defaults: {"projectId":"im4aehvv","dataset":"production"} }
}
        